import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Heading, Spinner, Text, Grid, GridItem, Button } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DashboardChart.css'; // Import your custom CSS

const API_URL = '/api';

const DashboardChart = () => {
  const [chartData, setChartData] = useState([]);
  const [latestLog, setLatestLog] = useState(null);
  const [mostActiveUser, setMostActiveUser] = useState(null);
  const [dateWithMostActivities, setDateWithMostActivities] = useState(null);
  const [newestUsers, setNewestUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for date range picker
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // State for detailed active users
  const [detailedActiveUsers, setDetailedActiveUsers] = useState([]);
  const [fetchError, setFetchError] = useState(null); // Error state for fetching detailed active users

  useEffect(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    setStartDate(thirtyDaysAgo);
    setEndDate(today);

    fetchData(thirtyDaysAgo, today);
    fetchNewestUsers(); // Fetch newest users on component mount
  }, []);

  const fetchData = async (startDate, endDate) => {
    try {
      setLoading(true);

      const params = {
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
      };

      const endpoints = [
        '/total-user',
        '/total-ref-user',
        '/total-idea-gen',
        '/total-post-gen',
        '/total-copy'
      ];

      const responses = await Promise.all(endpoints.map(endpoint => axios.get(`${API_URL}${endpoint}`, { params })));

      const chartLabels = [
        'Total Users', 
        'Total Ref Users', 
        'Total Idea Gen', 
        'Total Post Gen', 
        'Total Copy'
      ];

      const borderColors = [
        'rgba(75, 192, 192, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ];

      const backgroundColors = [
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ];

      const chartData = responses.slice(0, 5).map((res, index) => ({
        label: chartLabels[index],
        data: res.data.map(entry => ({ x: new Date(entry[0]), y: entry[1] })),
        borderColor: borderColors[index],
        backgroundColor: backgroundColors[index],
        fill: false,
      }));

      setChartData(chartData);

      const [latestLogResponse, mostActiveUserResponse, dateWithMostActivitiesResponse] = await Promise.all([
        axios.get(`${API_URL}/latest-log`, { params }),
        axios.get(`${API_URL}/most-active-user`, { params }),
        axios.get(`${API_URL}/date-with-most-actions`, { params }),
      ]);

      setLatestLog(latestLogResponse.data);
      setMostActiveUser(mostActiveUserResponse.data);
      setDateWithMostActivities(dateWithMostActivitiesResponse.data);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchNewestUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/newest-users`);
      setNewestUsers(response.data);
      setFetchError(null);
    } catch (error) {
      console.error('Error fetching newest users:', error);
      setFetchError('Failed to load newest users.');
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const applyDateRange = () => {
    fetchData(startDate, endDate);
  };

  const fetchDetailedActiveUser = async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/detailed-active-users`, { params: { user_id: userId } });
      setDetailedActiveUsers(response.data);
      setFetchError(null);
    } catch (error) {
      console.error('Error fetching detailed active users:', error);
      setFetchError('Failed to load detailed active users.');
    }
  };

  const highlightDates = [
    {
      date: new Date(2024, 6, 15),
      content: 'RTD day', // Content for the highlighted date
    },
    {
        date: new Date(2024, 6, 16),
        content: 'RTD day', // Content for the highlighted date
    },
    {
        date: new Date(2024, 6, 20),
        content: 'BKI', // Content for the highlighted date
    },
    {
        date: new Date(2024, 6, 21),
        content: 'SWC', // Content for the highlighted date
    },
  ];

  if (loading) {
    return (
      <Box p={5} textAlign="center">
        <Spinner size="lg" />
        <Text mt={4}>Loading data...</Text>
      </Box>
    );
  }

  return (
    <Box p={5}>
      <Heading as="h2" mb={5}>TIKERA Dashboard</Heading>

      <Box mb={5} d="flex" alignItems="center">
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          renderDayContents={(day, date) => {
            const highlight = highlightDates.find(d => d.date.toDateString() === date.toDateString());
            return (
              <span
                className={highlight ? 'highlighted-date' : ''}
                data-content={highlight ? highlight.content : ''}
              >
                {day}
              </span>
            );
          }}
        />
        <Button onClick={applyDateRange} colorScheme="blue" ml={3}>Apply Date Range</Button>
      </Box>

      <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} mb={5}>
        {latestLog && latestLog.length > 0 && (
          <GridItem>
            <Box borderWidth={1} borderRadius="md" p={4} bg="white" boxShadow="md" maxHeight="300px" overflowY="scroll">
              <Heading as="h3" size="lg" mb={3} fontSize="xl">Latest Log</Heading>
              {latestLog.map((log, index) => (
                <Box key={index} mb={3} p={3} borderWidth={1} borderRadius="md" bg="gray.50" boxShadow="sm">
                  <Text><strong>User ID:</strong> {log.id}</Text>
                  <Text><strong>Action:</strong> {log.action}</Text>
                  <Text><strong>Date:</strong> {new Date(log.time).toLocaleString()}</Text>
                </Box>
              ))}
            </Box>
          </GridItem>
        )}

        {mostActiveUser && mostActiveUser.user_id.length > 0 && (
          <GridItem>
            <Box borderWidth={1} borderRadius="md" p={4} bg="white" boxShadow="md" maxHeight="300px" overflowY="scroll">
              <Heading as="h3" size="lg" mb={3} fontSize="xl">Most Active User</Heading>
              {mostActiveUser.user_id.map((userId, index) => (
                <Box key={userId} mb={3} p={3} borderWidth={1} borderRadius="md" bg="gray.50" boxShadow="sm">
                  <Text><strong>User ID:</strong> {userId}</Text>
                  <Text><strong>Activity Count:</strong> {mostActiveUser.activity_count[index]}</Text>
                  <Button mt={3} onClick={() => fetchDetailedActiveUser(userId)} colorScheme="teal">Fetch Details</Button>
                  {detailedActiveUsers && detailedActiveUsers.user_id === userId && (
                    <>
                      <Text><strong>Email:</strong> {detailedActiveUsers.email}</Text>
                      <Text><strong>Account Created Date:</strong> {new Date(detailedActiveUsers.date_created).toLocaleString()}</Text>
                      <Text><strong>Last Active:</strong> {new Date(detailedActiveUsers.last_active).toLocaleString()}</Text>
                    </>
                  )}
                  {fetchError && <Text mt={2} color="red.500">{fetchError}</Text>}
                </Box>
              ))}
            </Box>
          </GridItem>
        )}

        {dateWithMostActivities && (
          <GridItem>
            <Box borderWidth={1} borderRadius="md" p={4} bg="white" boxShadow="md">
              <Heading as="h3" size="lg" mb={3}>Date with Most Activities</Heading>
              <Text><strong>Date:</strong> {new Date(dateWithMostActivities.date).toLocaleDateString()}</Text>
              {dateWithMostActivities.action_counts && Object.entries(dateWithMostActivities.action_counts).map(([action, count], index) => (
                <Text key={index}><strong>{action}:</strong> {count}</Text>
              ))}
            </Box>
          </GridItem>
        )}

        {newestUsers && newestUsers.length > 0 && (
          <GridItem>
            <Box borderWidth={1} borderRadius="md" p={4} bg="white" boxShadow="md" maxHeight="300px" overflowY="scroll">
              <Heading as="h3" size="lg" mb={3}>Newest Users</Heading>
              {newestUsers.map((user, index) => (
                <Box key={index} mb={3} p={3} borderWidth={1} borderRadius="md" bg="gray.50" boxShadow="sm">
                  <Text><strong>User ID:</strong> {user.user_id}</Text>
                  <Text><strong>Email:</strong> {user.email}</Text>
                  <Text><strong>Date Created:</strong> {new Date(user.date_created).toLocaleString()}</Text>
                </Box>
              ))}
            </Box>
          </GridItem>
        )}

      </Grid>

      <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6}>
        {chartData.map((data, index) => (
          <GridItem key={index}>
            <Box mb={5} borderWidth={1} borderRadius="md" p={4} bg="white" boxShadow="md">
              <Heading as="h3" size="lg" mb={3}>{data.label}</Heading>
              <Line
                data={{ datasets: [data] }}
                options={{
                  scales: {
                    x: {
                      type: 'time',
                      time: {
                        unit: 'day',
                        tooltipFormat: 'dd/MM/yyyy',
                      },
                      title: {
                        display: true,
                        text: 'Date',
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Count',
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                    },
                    tooltip: {
                      callbacks: {
                        label: function(tooltipItem) {
                          return `${tooltipItem.dataset.label}: ${tooltipItem.raw.y}`;
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardChart;
