// src/App.js
import React, { useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import DashboardChart from './DashboardChart';
import LoginForm from './LoginForm';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Box minH="100vh" bg="gray.100" p="4">
      {isLoggedIn ? (
        <DashboardChart />
      ) : (
        <Center minH="100vh">
          <LoginForm onLogin={handleLogin} />
        </Center>
      )}
    </Box>
  );
}

export default App;
